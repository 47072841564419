import React from 'react'
import moment from 'moment'

import Button from './Button'

type PortfolioProps = {
    backgroundColor: string
    title: string
    logo?: SVGAElement | string | JSX.Element
    projectTimeline: Array<Date>
    tags?: string[]
    description: string
    organization: string
    marginTop?: boolean
    projectRole: string
    pngImage?: string | Element
    caseStudyUrl?: string | null
}

const Portfolio = ({
    backgroundColor,
    title,
    projectTimeline,
    description,
    organization,
    projectRole,
    caseStudyUrl,
    marginTop,
    logo,
    tags,
    pngImage
}: PortfolioProps) => {
    const paragraphStyles = {
        fontSize: '14px',
        textalign: 'left',
        paddingTop: '10px',
        marginBottom: '10px'
    }

    const Tags = ({ projectTags }: { projectTags: string[] }) => {
        const tagStyles = {
            backgroundColor: 'rgb(99 99 99 / 10%)',
            border: 'none',
            padding: '0px 10px',
            textDecoration: 'none',
            display: 'inline-block',
            margin: '4px 2px',
            cursor: 'pointer',
            borderRadius: '32px',
            fontSize: '10px',
            textalign: 'center'
        }

        return (
            <span
                className="project-tags"
                style={{
                    display: 'block',
                    margin: '0 0 0 -5px',
                    textAlign: 'left'
                }}
            >
                {projectTags.map((tag) => (
                    <span className="project-tag" style={tagStyles}>
                        {tag}
                    </span>
                ))}
            </span>
        )
    }
    return (
        <section
            style={{
                backgroundColor,
                minHeight: '140px',
                padding: '40px 40px 0',
                fontWeight: 400,
                marginTop: `${marginTop ? '40px' : 0}`,
                paddingBottom: 40,
                borderRadius: 5
            }}
        >
            <header
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    marginBottom: '-15px'
                }}
            >
                <h2
                    className="title"
                    style={{
                        fontSize: '20px',
                        color: 'rgba(0,0,0,0.7)',
                        textAlign: 'left'
                    }}
                >
                    {title}
                </h2>
                {logo &&
                    (typeof logo === 'string' ? (
                        <img
                            src={logo}
                            width="30"
                            height="auto"
                            alt={`logo-${title}`}
                        />
                    ) : (
                        logo
                    ))}
            </header>
            <span
                className="timeline"
                style={{
                    display: 'block',
                    textAlign: 'left',
                    fontSize: '12px',
                    fontWeight: 'bold',
                    textTransform: 'uppercase',
                    marginBottom: '10px',
                    color: 'rgb(0 0 0 / 40%)'
                }}
            >
                {projectTimeline.length > 1
                    ? projectTimeline
                          .map((dt) => moment(dt).format('MMM YYYY'))
                          .reduce(
                              (pre, cur) =>
                                  `${pre} • ${moment(pre).from(
                                      moment(cur),
                                      true
                                  )}`
                          )
                    : `${moment(projectTimeline[0]).format(
                          'MMM YYYY'
                      )} • Present(${moment(projectTimeline[0]).fromNow(
                          true
                      )})`}
            </span>
            {tags && Tags({ projectTags: tags })}
            <p style={{ ...paragraphStyles, textAlign: 'justify' }}>
                {description}
            </p>
            <p
                className="meta-info"
                style={{
                    ...paragraphStyles,
                    textAlign: 'justify',
                    marginBottom: 0,
                    lineHeight: 0.1
                }}
            >
                Organization{' '}
                <span style={{ color: 'rgba(0,0,0,0.7)' }}>{organization}</span>
            </p>
            <p
                className="meta-info"
                style={{
                    ...paragraphStyles,
                    textAlign: 'justify',
                    marginBottom: 20
                }}
            >
                Role{' '}
                <span style={{ color: 'rgba(0,0,0,0.7)' }}>{projectRole}</span>
            </p>
            {pngImage && typeof pngImage === 'string' ? (
                <img src={pngImage} alt={`project-${title}`} />
            ) : (
                pngImage
            )}
            {caseStudyUrl && (
                <Button
                    onClick={() => window.open(caseStudyUrl, '_blank')}
                    text="Case Study"
                    styles={{ marginBottom: 0, display: 'inherit' }}
                />
            )}
        </section>
    )
}

export default Portfolio
