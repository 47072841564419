import React from 'react'

type FooterProps = {
    heading: string | JSX.Element
    footerText: string | JSX.Element
}

const Footer = ({ heading, footerText }: FooterProps) => (
    <section
        className="footer"
        style={{
            backgroundColor: '#F2F2F2',
            minHeight: '140px',
            padding: '40px 40px 10px',
            fontWeight: 400,
            borderRadius: 5
        }}
    >
        <h6 style={{ margin: 0, marginBottom: 10, fontSize: '16px' }}>
            {heading}
        </h6>
        <p style={{ fontSize: '12px' }}>{footerText}</p>
    </section>
)

export default Footer
