import React from 'react'
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry'

import '../App.css'
import Footer from '../components/footer'
import Introduction from '../components/introduction'
import Layout from '../components/layout'
import Portfolio from '../components/portfolio'
import SEO from '../components/seo'
import Socials from '../components/socials'
import { HeartIcon, IvanX, SmartControl } from '../icons'
import { PortfolioItem } from '../libs/types'
import FundavisionLogo from '../img/fundavision.png'

function MainApp() {
    const footerHeading = () => (
        <>
            Portfolio made with
            <HeartIcon
                style={{ padding: '0 3px' }}
                className="w-2 h-2 bg-red"
            />{' '}
            in Lahore, Pakistan
        </>
    )
    const footerText = () => (
        <>Copyright &copy; 2019 Sheraz Ahmed. All rights reserved.</>
    )

    const SEO_ATTR = {
        title: "Sheraz's Portfolio",
        description: "Sheraz's Work History and Portfolio",
        lang: 'eng',
        meta: []
    }

    const PORTFOLIO: Array<PortfolioItem> = [
        {
            title: 'TO BE UPDATED!',
            description:
                'Due to recent changes in the work contracts, portfolio items have been updated and made private. \nPlease feel free to reach out for project information if needed, otherwise items will be updated soon.',
            tags: [
                'react js',
                'typescript',
                'mongodb',
                'expressjs',
                'gitlab ci',
                'solidity',
                'web3',
                'hotjar',
                'sentry',
                'Web3.0',
                'ipfs',
                'dApps',
                'networking items'
            ],
            role: 'Solution Architect',
            timeline: [new Date('Oct, 2021'), new Date()],
            organization: 'InvoZone Pvt. Ltd.',
            logo: 'https://i.ibb.co/VWgdsWw/invozone.jpg'
        }
        // {
        //     title: 'Artefy',
        //     description: 'Blockchain NFT Marketplace for MATIC Polygon',
        //     tags: [
        //         'react js',
        //         'typescript',
        //         'mongodb',
        //         'expressjs',
        //         'gitlab ci',
        //         'solidity',
        //         'web3',
        //         'hotjar',
        //         'sentry'
        //     ],
        //     role: 'Solution Architect',
        //     timeline: [new Date('Oct, 2021'), new Date('Feb, 2022')],
        //     organization: 'Artefy.io',
        //     logo: 'https://i.ibb.co/x24cWv4/artefy.png'
        // },
        // {
        //     title: 'Column Us',
        //     description:
        //         'Column.us is the first collaborative public notice platform helping journalists, governments, and legal services work together to inform their communities.',
        //     tags: [
        //         'react js',
        //         'typescript',
        //         'firebase',
        //         'firestore',
        //         'circle ci',
        //         'log rocket',
        //         'sentry'
        //     ],
        //     role: 'Associate Project Manager',
        //     timeline: [new Date('Jun, 2020'), new Date('Oct, 2021')],
        //     organization: 'Column.us',
        //     logo: 'https://i.ibb.co/8jvVtxY/column.png'
        // },
        // {
        //     title: 'PAM (Privileged Access Management)',
        //     description:
        //         'RevBits Privileged Access Management is a six-in-one solution that includes privileged access, privileged session, password, service accounts, key and certificate management, as well as extensive session logging that captures keystrokes and video. RevBits Privileged Access Management native clients are available for common operating systems.',
        //     tags: [
        //         'angular js',
        //         'typescript',
        //         'postgres',
        //         'express',
        //         'unit testing',
        //         'sentry'
        //     ],
        //     role: 'Senior Software Engineer',
        //     timeline: [new Date('Jan, 2020'), new Date('May, 2020')],
        //     organization: 'Invozone',
        //     logo: 'https://i.ibb.co/yd2RQ3C/pam.png'
        // },
        // {
        //     title: 'Managed OTT',
        //     description:
        //         'The Intelligent Video Access Network (iVAN-X) platform maintains a position as the front runner in the race to efficiently launch scalable white-label OTT TV services equipped out of the box with pioneering monetisation technologies.',
        //     tags: [
        //         'php',
        //         'javascript',
        //         'bitmovin integration',
        //         'redis',
        //         'postgres',
        //         'aws'
        //     ],
        //     role: 'Senior Software Engineer',
        //     timeline: [new Date('Nov, 2019'), new Date('Jan, 2020')],
        //     organization: 'TV2U',
        //     logo: <IvanX style={{ width: 100 }} />,
        //     caseStudy: 'https://www.tv2u.com/'
        // },
        // {
        //     title: 'Fundavision',
        //     description:
        //         'A one stop place for all your business needs and services',
        //     tags: ['java', 'angular js', 'postgres', 'aws'],
        //     role: 'Software Engineer',
        //     timeline: [new Date('Jan, 2019'), new Date('Jul, 2019')],
        //     organization: 'Fundavision',
        //     logo: FundavisionLogo
        // },
        // {
        //     title: 'E-Learn',
        //     description:
        //         'The official repository of digitized textbooks. Each book has been augmented with Video Lectures, Illustrations, Animations, Simulations and Interactive Assessments',
        //     tags: ['laravel', 'vue js', 'mysql', 'cron jobs', 'data cleansing'],
        //     role: 'Software Engineer',
        //     timeline: [new Date('Oct, 2018'), new Date('Jan, 2019')],
        //     organization: 'PITB - Elearn',
        //     logo: 'https://www.elearn.gov.pk/imagesnew/app1.png',
        //     caseStudy: 'https://www.elearn.gov.pk/'
        // },
        // {
        //     title: 'AI IoT things',
        //     description:
        //         'A Pakistani Startup company working on the Internet of things. Automation of household appliances and keeping records and logs and optimizing energy usage.',
        //     tags: [
        //         'laravel',
        //         'vue js',
        //         'mysql',
        //         'socket io',
        //         'firebase',
        //         'flutter'
        //     ],
        //     role: 'Software Engineer',
        //     timeline: [new Date('Oct, 2018'), new Date('Jan, 2019')],
        //     organization: 'Freelance',
        //     logo: <SmartControl className="w-4" width="30" height="30" />
        // }
    ]
    const backgroundColors = [
        '#D6E7FF',
        '#ffd6d6',
        '#f9dfcf',
        '#dde7ff',
        '#ddffef',
        '#ecd6ff',
        '#f2f2f2'
    ]

    return (
        <div className="App">
            <Layout>
                <SEO {...SEO_ATTR} />
                <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 750: 2 }}>
                    <Masonry gutter="40px">
                        {/* <div className="main-container">
                    <div className="cols-container"> */}
                        <Introduction />
                        {PORTFOLIO.map(
                            (portfolio: PortfolioItem, index: number) =>
                                index === 0 ? (
                                    [
                                        <Socials
                                            email="hi@isheraz.com"
                                            twitter="https://twitter.com/isherazahmed"
                                            github="https://github.com/isheraz"
                                            linkedIn="https://www.linkedin.com/in/isheraz"
                                        />,
                                        <Portfolio
                                            backgroundColor={
                                                backgroundColors[index]
                                            }
                                            title={portfolio.title}
                                            tags={portfolio.tags}
                                            projectTimeline={portfolio.timeline}
                                            description={portfolio.description}
                                            organization={
                                                portfolio.organization
                                            }
                                            projectRole={portfolio.role}
                                            logo={portfolio.logo}
                                            caseStudyUrl={
                                                portfolio.caseStudy || null
                                            }
                                        />
                                    ]
                                ) : (
                                    <Portfolio
                                        backgroundColor={
                                            backgroundColors[index]
                                        }
                                        title={portfolio.title}
                                        tags={portfolio.tags}
                                        projectTimeline={portfolio.timeline}
                                        description={portfolio.description}
                                        organization={portfolio.organization}
                                        projectRole={portfolio.role}
                                        logo={portfolio.logo}
                                        caseStudyUrl={
                                            portfolio.caseStudy || null
                                        }
                                    />
                                )
                        )}
                        <Footer
                            heading={footerHeading()}
                            footerText={footerText()}
                        />
                    </Masonry>
                </ResponsiveMasonry>
            </Layout>
        </div>
    )
}

export default MainApp
