import React from 'react'
import Button from './Button'

type IntroductionProps = {
    heading?: string | JSX.Element
    footerText?: string | JSX.Element
}

const Introduction = (_props: IntroductionProps) => {
    const HighlightedText = ({ children }: { children: string }) => (
        <span
            style={{
                backgroundColor: '#fbeb32c2',
                borderRadius: '50px 20px 50px 50px',
                paddingTop: 5
            }}
        >
            {children}
        </span>
    )
    return (
        <section
            className="footer"
            style={{
                backgroundColor: '#F2F2F2',
                minHeight: '140px',
                padding: '40px 40px 10px',
                fontWeight: 400,
                // marginTop: 40,
                borderRadius: 5
            }}
        >
            <h4
                style={{
                    fontWeight: 500,
                    fontSize: 36,
                    lineHeight: 0.7,
                    textAlign: 'justify'
                }}
            >
                Sheraz Ahmed
                <small style={{ fontSize: 14 }}>
                    &nbsp; has a strong grip and hands-on expertise in{' '}
                    JavaScript, TypeScript, Node.js, React, Next.js, NestJS,
                    Express, Test automation, Cloud Platform GCP and database.
                    Streamlining development with preferred following tools;{' '}
                    <HighlightedText>
                        Git, GitHub Actions, CI/CD, Visual Studio Code, Lint,
                        State management and Docker
                    </HighlightedText>{' '}
                    for the development process as well as keeping the team on
                    the same page for code quality.
                    <HighlightedText>
                        He is a polyglot engineer and able to adapt to new tech
                        stacks with very minimal time
                    </HighlightedText>{' '}
                    and always solving problems with out of the box solutions
                    that fit the scenario best. Able to Lead and follow Scrum
                    and Team to finish up sprints and or revision cycles in a
                    fast paced environment.
                </small>
            </h4>
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between'
                }}
            >
                <Button
                    text="about"
                    onClick={(
                        e: React.MouseEvent<HTMLButtonElement, MouseEvent>
                    ) => {
                        e.preventDefault()
                        window.open(
                            'https://docs.google.com/document/d/1b0ZlxToGqcd0Zw9LYZA9OcOAJyVk-aDlTEE2o7HngiA/edit?usp=sharing',
                            '_blank'
                        )
                    }}
                />
            </div>
        </section>
    )
}

export default Introduction
