import React from 'react'
import PropTypes from 'prop-types'

import './layout.css'

type LayoutProps = {
    children: any
}

const Layout = ({ children }: LayoutProps) => (
    <>
        <div
            style={{
                margin: '0 auto',
                maxWidth: 1024,
                padding: '2.45rem 1.0875rem 1.45rem',
                position: 'relative'
            }}
        >
            <main>{children}</main>
        </div>
    </>
)

Layout.propTypes = {
    children: PropTypes.node.isRequired
}

export default Layout
