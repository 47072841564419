/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
// import { useStaticQuery, graphql } from "gatsby"

type SEOProps = {
    description?: string
    lang?: string
    // meta?: string[]
    title: string | undefined
    site?: any
}

function SEO({ description, lang, title, site }: SEOProps) {
    // const { site } = useStaticQuery(
    //   graphql`
    //     query {
    //       site {
    //         siteMetadata {
    //           title
    //           description
    //           author
    //         }
    //       }
    //     }
    //   `
    // )

    const metaDescription = description
    // const defaultTitle = title

    return (
        <Helmet
            htmlAttributes={{
                lang
            }}
            title={title}
            // titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : null}
            meta={[
                {
                    name: 'description',
                    content: metaDescription
                },
                {
                    property: 'og:title',
                    content: title
                },
                {
                    property: 'og:description',
                    content: metaDescription
                },
                {
                    property: 'og:type',
                    content: 'website'
                },
                {
                    name: 'twitter:card',
                    content: 'summary'
                },
                {
                    name: 'twitter:creator',
                    content: site?.siteMetadata?.author || ''
                },
                {
                    name: 'twitter:title',
                    content: title
                },
                {
                    name: 'twitter:description',
                    content: metaDescription
                }
            ]}
            // ].concat(meta)}
        />
    )
}

SEO.defaultProps = {
    lang: 'en',
    // meta: [],
    description: `Sheraz's Portfolio Website and Blog. Sheraz is a Software Solution Architect and Engineer with ${
        new Date().getFullYear() - 2015
    }+ years of experience`
}

SEO.propTypes = {
    description: PropTypes.string,
    lang: PropTypes.string,
    // meta: PropTypes.arrayOf(PropTypes.object),
    title: PropTypes.string.isRequired
}

export default SEO
