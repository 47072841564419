import React from 'react'

type ButtonProps = {
    text: string
    onClick: React.MouseEventHandler<HTMLButtonElement>
    styles?: React.CSSProperties
}

const Button = (props: ButtonProps) => (
    <button onClick={props.onClick} style={props.styles}>
        {props.text}
    </button>
)

export default Button
