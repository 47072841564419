import React from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import MainApp from './pages/Index'
import Privacy from './pages/kcars/privacy'

export default function App() {
    return (
        <Router>
            <Routes>
                <Route path="/kcars/privacy" element={<Privacy />} />
                <Route path="/" element={<MainApp />} />
            </Routes>
        </Router>
    )
}
