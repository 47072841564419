import { ReactComponent as heart } from './heart.svg'
import { ReactComponent as github } from './github.svg'
import { ReactComponent as linkedIn } from './linkedin-original.svg'
import { ReactComponent as twitter } from './twitter-filled.svg'
import { ReactComponent as facebook } from './facebook.svg'
import { ReactComponent as instagram } from './instagram.svg'
import { ReactComponent as dribbble } from './dribbble.svg'
import { ReactComponent as ivanx } from './ivan-x.svg'
import { ReactComponent as smartControl } from './smart-control.svg'

export const HeartIcon = heart
export const Git = github
export const LinkedIn = linkedIn
export const Twitter = twitter
export const Dribbble = dribbble
export const Instagram = instagram
export const Facebook = facebook
export const IvanX = ivanx
export const SmartControl = smartControl
