/* eslint-disable react/jsx-no-target-blank */
import React from 'react'

import Button from './Button'
import { Twitter, Git, Instagram, Facebook, Dribbble, LinkedIn } from '../icons'

type SocialsProps = {
    facebook?: string
    instagram?: string
    dribbble?: string
    github?: string
    twitter?: string
    linkedIn?: string
    email: string
}

const Socials = (props: SocialsProps) => (
    <section
        className="footer"
        style={{
            backgroundColor: '#F2F2F2',
            minHeight: '40px',
            maxHeight: '200px',
            padding: '40px 40px 10px',
            fontWeight: 400,
            borderRadius: 5,
            display: 'flex',
            justifyContent: 'space-between'
        }}
    >
        <Button
            text="hit me up!"
            onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                e.preventDefault()
                window.open(`mailto:${props.email}`, '_blank')
            }}
        />
        <div id="socials">
            {props.twitter && (
                <a
                    href={props.twitter}
                    target="_blank"
                    id="sa-p-twitter"
                    className="social-icon"
                >
                    <Twitter className="w-3" style={{ fill: '#1DA1F2' }} />
                </a>
            )}
            {props.instagram && (
                <a
                    target="_blank"
                    href={props.instagram}
                    id="sa-p-instagram"
                    className="social-icon"
                >
                    <Instagram className="w-3" style={{ fill: '#E4405F' }} />
                </a>
            )}
            {props.github && (
                <a
                    target="_blank"
                    href={props.github}
                    id="sa-p-github"
                    className="social-icon"
                >
                    <Git className="w-3" style={{ fill: '#181717' }} />
                </a>
            )}
            {props.facebook && (
                <a
                    target="_blank"
                    href={props.facebook}
                    id="sa-p-facebook"
                    className="social-icon"
                >
                    <Facebook className="w-3" style={{ fill: '#1877F2' }} />
                </a>
            )}
            {props.dribbble && (
                <a
                    target="_blank"
                    href={props.dribbble}
                    id="sa-p-dribbble"
                    className="social-icon"
                >
                    <Dribbble className="w-3" style={{ fill: '#EA4C89' }} />
                </a>
            )}
            {props.linkedIn && (
                <a
                    target="_blank"
                    href={props.linkedIn}
                    id="sa-p-linkedIn"
                    className="social-icon"
                >
                    <LinkedIn className="w-3" style={{ fill: '#0A66C2' }} />
                </a>
            )}
        </div>
    </section>
)

export default Socials
